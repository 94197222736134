import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Hotjar from '@hotjar/browser';

// Lazy load components
const LandingPage = React.lazy(() => import('./Landingpage'));
const CarOwner = React.lazy(() => import('./CarOwner'));
const VehicleOwner = React.lazy(() => import('./CarOwner'));
const VehicleDownload = React.lazy(() => import('./GarageDownload'));
const Garage = React.lazy(() => import('./Garage'));
const Mobile = React.lazy(() => import('./Mobile'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const Terms = React.lazy(() => import('./Terms'));
const ContactUs = React.lazy(() => import('./ContactUs'));
const AboutUs = React.lazy(() => import('./AboutUs'));
const MobileCarOwner = React.lazy(() => import('./MobilecarOwner'));
const MobilePolicy = React.lazy(() => import('./MobilePolicy'));
const TermsofService = React.lazy(() => import('./MobileTerms'));
const MobileAboutUs = React.lazy(() => import('./MobileAbout'));
const MobileDownload = React.lazy(() => import('./MobileDownloadGarage'));
const Docs = React.lazy(() => import('./Docs'));
const FAQs = React.lazy(() => import('./FAQs'));
const Career = React.lazy(() => import('./Career'));

// Hotjar initialization
const siteId = 5271012;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

// Loading component
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router basename="/">
      <Suspense fallback={<LoadingFallback />}>
        {width < 600 ? (
          // Mobile routing
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/car-owner" element={<VehicleOwner />} />
            <Route path="/vehicle-download" element={<MobileCarOwner />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/privacy-policy" element={<MobilePolicy />} />
            <Route path="/terms-of-services" element={<TermsofService />} />
            <Route path="/garage-owner" element={<Garage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/aboutus" element={<MobileAboutUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/garage-download" element={<MobileDownload />} />
          </Routes>
        ) : (
          // Desktop routing
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/home" element={<LandingPage />} />
              <Route path="/vehicle-owner-download" element={<CarOwner />} />
              <Route path="/garage-owner-download" element={<VehicleDownload />} />
              <Route path="/car-owner" element={<VehicleOwner />} />
              <Route path="/garage-owner" element={<Garage />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/home-page" element={<Mobile />} />
              <Route path="/docs" element={<Docs />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/career" element={<Career />} />
            </Routes>
          </>
        )}
      </Suspense>
    </Router>
  );
}

export default App;
